// pages/404.tsx
import { GetStaticProps } from 'next'
import NextErrorComponent from 'next/error'
import { useTranslation } from 'next-i18next'
import { getServerSideTranslations } from '../utils/i18n_server'

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const translations = await getServerSideTranslations(locale, ['common'])

    return {
        props: {
            ...translations,
        },
    }
}

const NotFoundPage = () => {
    const { t } = useTranslation()
    return (
        <NextErrorComponent
            withDarkMode={false}
            statusCode={404}
            title={t('error.404.title')}
        />
    )
}

export default NotFoundPage
